import { lazy } from 'react';
import { ProtectedLayout } from 'components/layout/ProtectedLayout';
import { RootRoutes } from 'enums/rootRoutes';
import { RouteObject } from 'react-router-dom';
import { AssetType } from 'api/generated/graphql';
import i18n from 'i18next';
import { Providers } from 'providers/Providers';
import {
  DynamicAssetDetailsBreadcrumb,
  DynamicOrganizationDetailsBreadcrumb,
  DynamicProjectDetailsBreadcrumb,
  DynamicReservationDetailsBreadcrumb,
  DynamicTimelinesBreadcrumb,
  DynamicUserProfileBreadcrumb,
} from './BreadCrumbs';

const GraphqlPlayground = lazy(() => import('modules/admin/GraphqlPlayground'));
const AssetListPage = lazy(() => import('modules/assetList'));
const AssetDetailsPage = lazy(() => import('modules/assetDetails'));
const ProjectsPage = lazy(() => import('modules/projects'));
const ReservationsPage = lazy(() => import('modules/reservations'));
const ProjectDetailsPage = lazy(() => import('modules/projectDetails'));
const ReservationDetailsPage = lazy(() => import('modules/reservationDetails'));
const DashboardPage = lazy(() => import('modules/dashboard'));
const MapPage = lazy(() => import('modules/map'));
const NotFound = lazy(() => import('modules/errorPages/notFound'));
const AssetModelsAndVendorsPage = lazy(() => import('modules/assetModelsAndVendors'));
const UserManagementPage = lazy(() => import('modules/userManagement'));
const UserProfilePage = lazy(() => import('modules/userProfile'));
const TimelinesPage = lazy(() => import('modules/timelines'));
const FullEventLog = lazy(() => import('modules/fullEventLog'));
const SingleAssetTimelinePage = lazy(() => import('modules/singleAssetTimeline'));
const OrganizationsPage = lazy(() => import('modules/organizations'));
const OrganizationDetailsPage = lazy(() => import('modules/organizationDetails'));
const PolicyAdminPage = lazy(() => import('modules/termsAndPolicy'));
const TermsAndPolicyViewer = lazy(() => import('modules/termsAndPolicyViewer'));
const TermsAndPolicyEditorPage = lazy(() => import('modules/termsAndPolicyEditor'));
const RoleAdminPage = lazy(() => import('modules/admin/roleAdmin'));
const RoleEditPage = lazy(() => import('modules/admin/roleAdmin/RoleEditPage'));
const OwnProfilePage = lazy(() => import('modules/userProfile/OwnProfilePage'));
const DecommissionedAssetsPage = lazy(() => import('modules/decommissionedAssets'));

/**
 * This file contains the routes for the application.
 * The routes are defined as an array of objects, where each object represents a route.
 *
 * We have added a custom property called 'handle' to the route object, which contains
 * the following properties:
 * - crumb: A function that returns a JSX element that will be used as the breadcrumb for the route.
 * - auth: An object that contains the following properties:
 *  - permissions: An array of permissions that the user must have to access the route.
 *  - internal: A boolean that indicates whether the user must be internal to access the route.
 *
 * The purpose of having the auth property defined separately, and not as a wrapping Protector element
 * is to be able to use the auth information in the useRouteAccess hook, which is used to determine
 * whether the user has access to the route.
 *
 * By doing so, we do not spread the logic for determining access to a route across multiple files.
 *
 * Important: Auth is inherited by child routes. This means that if a parent route has a permission
 * defined, the child routes will also require that permission. If the child route has a different
 * permission defined, the child route will require both permissions.
 *
 * Important: In order for the auth property to work, the application must be wrapped in a <RouteProtector> element.
 */

const ProviderLayout = ({ noSidebar }: { noSidebar?: boolean }) => (
  <Providers>
    <ProtectedLayout noSidebar={noSidebar} />
  </Providers>
);

export interface AuthorizedRouteObject extends Omit<RouteObject, 'children'> {
  children?: AuthorizedRouteObject[];
  handle?: {
    crumb?: () => JSX.Element | string;
  };
}

// This needs to be exported as a function, to avoid circular dependencies
export const getRouteConfig = (): AuthorizedRouteObject[] => [
  {
    path: '/',
    element: <ProviderLayout />,
    children: [
      {
        index: true,
        element: <DashboardPage />,
        handle: {
          crumb: () => i18n.t('DASHBOARD'),
        },
      },
      {
        path: RootRoutes.ASSETS,
        handle: {
          crumb: () => i18n.t('ASSET_other'),
        },
        children: [
          {
            index: true,
            element: <AssetListPage assetType={AssetType.Generic} />,
          },
          {
            path: RootRoutes.ASSET_DETAILS,
            handle: {
              crumb: () => <DynamicAssetDetailsBreadcrumb />,
            },

            children: [
              {
                index: true,
                element: <AssetDetailsPage />,
              },
              {
                path: RootRoutes.ASSET_TIMELINE,
                element: <SingleAssetTimelinePage />,
                handle: {
                  crumb: () => i18n.t('TIMELINE'),
                },
              },
            ],
          },
        ],
      },
      {
        path: RootRoutes.BATTERIES,
        handle: {
          crumb: () => i18n.t('BATTERY_other'),
        },
        children: [
          {
            index: true,
            element: <AssetListPage assetType={AssetType.Battery} />,
          },
          {
            path: RootRoutes.BATTERY_DETAILS,
            handle: {
              crumb: () => <DynamicAssetDetailsBreadcrumb />,
            },

            children: [
              {
                index: true,
                element: <AssetDetailsPage />,
              },
              {
                path: RootRoutes.BATTERY_TIMELINE,
                element: <SingleAssetTimelinePage />,
                handle: {
                  crumb: () => i18n.t('TIMELINE'),
                },
              },
            ],
          },
        ],
      },

      {
        path: RootRoutes.CHARGERS,
        handle: {
          crumb: () => i18n.t('CHARGER_other'),
        },

        children: [
          {
            index: true,
            element: <AssetListPage assetType={AssetType.Charger} />,
          },
          {
            path: RootRoutes.CHARGER_DETAILS,

            handle: {
              crumb: () => <DynamicAssetDetailsBreadcrumb />,
            },
            children: [
              {
                index: true,
                element: <AssetDetailsPage />,
              },
              {
                path: RootRoutes.CHARGER_TIMELINE,
                element: <SingleAssetTimelinePage />,
                handle: {
                  crumb: () => i18n.t('TIMELINE'),
                },
              },
            ],
          },
        ],
      },

      {
        path: RootRoutes.SWITCHBOARDS,
        handle: {
          crumb: () => i18n.t('SWITCHBOARD_other'),
        },

        children: [
          {
            index: true,
            element: <AssetListPage assetType={AssetType.Switchboard} />,
          },
          {
            path: RootRoutes.SWITCHBOARD_DETAILS,
            handle: {
              crumb: () => <DynamicAssetDetailsBreadcrumb />,
            },
            children: [
              {
                index: true,
                element: <AssetDetailsPage />,
              },
              {
                path: RootRoutes.SWITCHBOARD_TIMELINE,
                element: <SingleAssetTimelinePage />,
                handle: {
                  crumb: () => i18n.t('TIMELINE'),
                },
              },
            ],
          },
        ],
      },

      {
        path: RootRoutes.ENERGY_METERS,
        handle: {
          crumb: () => i18n.t('ENERGY_METER_other'),
        },

        children: [
          {
            index: true,
            element: <AssetListPage assetType={AssetType.EnergyMeter} />,
          },
          {
            path: RootRoutes.ENERGY_METER_DETAILS,
            handle: {
              crumb: () => <DynamicAssetDetailsBreadcrumb />,
            },
            children: [
              {
                index: true,
                element: <AssetDetailsPage />,
              },
              {
                path: RootRoutes.ENERGY_METER_TIMELINE,
                element: <SingleAssetTimelinePage />,
                handle: {
                  crumb: () => i18n.t('TIMELINE'),
                },
              },
            ],
          },
        ],
      },

      {
        path: RootRoutes.RESERVATIONS,
        handle: {
          crumb: () => i18n.t('RESERVATION_other'),
        },

        children: [
          {
            index: true,
            element: <ReservationsPage />,
          },
          {
            path: RootRoutes.RESERVATION_DETAILS,
            element: <ReservationDetailsPage />,
            handle: {
              crumb: () => <DynamicReservationDetailsBreadcrumb />,
            },
          },
        ],
      },

      {
        path: RootRoutes.PROJECTS,
        handle: {
          crumb: () => i18n.t('PROJECT_other'),
        },

        children: [
          {
            index: true,
            element: <ProjectsPage />,
          },
          {
            path: RootRoutes.PROJECT_TAB,
            element: <ProjectDetailsPage />,
            handle: {
              crumb: () => <DynamicProjectDetailsBreadcrumb />,
            },
          },
        ],
      },
      {
        path: RootRoutes.MAP,
        handle: {
          crumb: () => i18n.t('MAP'),
        },
        element: <MapPage />,
      },
      {
        path: RootRoutes.MODELS_AND_VENDORS,
        element: <AssetModelsAndVendorsPage />,
        handle: {
          crumb: () => i18n.t('MODEL_other'),
        },
      },
      {
        path: RootRoutes.USERS,
        handle: {
          crumb: () => i18n.t('USER_other'),
        },
        children: [
          {
            index: true,
            element: <UserManagementPage />,
          },
          {
            path: RootRoutes.USER_PROFILE,
            element: <UserProfilePage />,
            handle: {
              crumb: () => <DynamicUserProfileBreadcrumb />,
            },
          },
        ],
      },
      {
        path: RootRoutes.OWN_PROFILE,
        element: <OwnProfilePage />,
        handle: {
          crumb: () => i18n.t('USER_PROFILE'),
        },
      },
      {
        path: RootRoutes.ORGANIZATIONS,
        handle: {
          crumb: () => i18n.t('ORGANIZATION_other'),
        },
        children: [
          {
            index: true,
            element: <OrganizationsPage />,
          },
          {
            path: RootRoutes.ORGANIZATION_DETAILS,
            element: <OrganizationDetailsPage />,
            handle: {
              crumb: () => <DynamicOrganizationDetailsBreadcrumb />,
            },
          },
        ],
      },

      {
        path: `${RootRoutes.TIMELINE}/*`,
        element: <TimelinesPage />,
        handle: {
          crumb: () => <DynamicTimelinesBreadcrumb />,
        },
      },
      {
        path: RootRoutes.EVENTS,
        element: <FullEventLog />,
        handle: {
          crumb: () => i18n.t('EVENT_other'),
        },
      },
      {
        path: RootRoutes.DECOMMISSIONED_ASSETS,
        element: <DecommissionedAssetsPage />,
        handle: {
          crumb: () => i18n.t('DECOMMISSIONED_ASSETS'),
        },
      },
      {
        path: RootRoutes.NOT_FOUND,
        element: <NotFound />,
      },
      {
        path: RootRoutes.POLICY_ADMIN,
        element: <PolicyAdminPage />,
      },
      {
        path: RootRoutes.POLICY_EDITOR,
        element: <TermsAndPolicyEditorPage />,
      },
      {
        path: RootRoutes.ROLE_ADMIN,
        handle: {
          crumb: () => i18n.t('ROLE_other'),
        },
        children: [
          {
            index: true,
            element: <RoleAdminPage />,
          },
          {
            path: RootRoutes.ROLE_EDIT,
            element: <RoleEditPage />,
          },
          {
            path: RootRoutes.ROLE_ADMIN_TAB,
            element: <RoleAdminPage />,
          },
        ],
      },
      {
        path: '*',
        element: <NotFound />,
      },
      {
        path: RootRoutes.GRAPQHL_PLAYGROUND,
        element: <GraphqlPlayground />,
      },
    ],
  },
  {
    path: '/',
    element: <ProviderLayout noSidebar />,
    children: [
      {
        path: RootRoutes.POLICIES,
        element: <TermsAndPolicyViewer />,
      },
    ],
  },
];
