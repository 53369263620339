import { format } from 'date-fns';
import { formatDuration, intervalToDuration, roundToNearestMinutes } from 'date-fns';
import { isValidDate } from './dates';

export const dateAndTimeFormat = 'dd.MM.yyyy HH:mm:ss'; // 24.11.2022 23:59:59

export const dateFormat = 'dd.MM.yy'; // 24.11.22
export const dateFormatFullYear = 'dd.MM.yyyy'; // 24.11.2022
export const dateFormatCompact = 'dd.MM'; // 24.11

export const dateFormatWordy = 'dd. MMM yy'; // 24.Nov 2022
export const dateFormatWordyCompact = 'dd. MMM'; // 24.Nov

export const dayMonthFormat = 'dd.MM'; // 24.11
export const timeFormat = 'HH:mm'; // 22:00
export const locale = 'nb-NO';
/**
 *
 * @param d Date object or ISOString (example: "2022-04-24T21:40:02Z")
 * @returns Standard formatted string with just day, month and year
 */
export const formatDate = (d: Date | string | undefined | null, customFormat?: string) => {
  if (d === undefined || d === null) return '-';

  const fmt = customFormat ?? dateFormat;
  const date = typeof d === 'string' ? new Date(d) : d;

  if (!isValidDate(date)) return 'INVALID';

  return format(date, fmt);
};

/**
 *
 * @param d Date object or ISOString (example: "2022-04-24T21:40:02Z")
 * @returns Standard formatted string with just hour and minute
 */
export const formatTime = (d: Date | string | undefined | null) => {
  if (d === undefined || d === null) return '-';
  return format(typeof d === 'string' ? new Date(d) : d, timeFormat);
};

/**
 *
 * @param d Date object or ISOString (example: "2022-04-24T21:40:02Z")
 * @returns Standard formatted string including hours, minutes and seconds
 */
export const formatDateAndTime = (d: Date | string | undefined | null) => {
  if (d === undefined || d === null) return '-';

  const dateAndTimeFormat = 'yyyy-MM-dd, HH:mm:ss';

  return format(typeof d === 'string' ? new Date(d) : d, dateAndTimeFormat);
};

/**
 *
 * @param d Date object or ISOString (example: "2022-04-24T21:40:02Z")
 * @returns Standard formatted string including hours, minutes and seconds
 */
export const formatDateRange = (
  from: Date | string | undefined,
  to: Date | string | undefined,
  wordy?: boolean,
  compact?: boolean
) => {
  if (from === undefined && to === undefined) return '-';

  const fmt = wordy
    ? compact
      ? dateFormatWordyCompact
      : dateFormatWordy
    : compact
      ? dateFormatCompact
      : dateFormat;

  const fromString = formatDate(from, fmt);
  const toString = formatDate(to, fmt);

  return `${fromString} - ${toString}`;
};

/**
 * Returns a formatted duration string between two dates. Ignores hours, to avoid summer/winter time shift.
 * @param start first date
 * @param end second date
 * @returns formatted duration string, without hours
 */
export const formatDurationBetween = (start: Date, end: Date) => {
  if (!start || !end) return '';

  if (start > end || isNaN(start.getTime()) || isNaN(start.getTime())) return 'Invalid';

  return formatDuration({
    ...intervalToDuration({ start, end: roundToNearestMinutes(end) }), // round to nearest minute since end dates are typically at 23:59:59
    hours: 0, // avoid summer/winter time shift
  });
};

// formats 1000000.00 as 1,000,000.00
export const formatNumber = (n: number | undefined | null, decimals = 2) => {
  if (n === undefined || n === null) return '-';

  return n.toLocaleString(locale, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
};
