import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { authAudience, authClientId, authDomain } from 'utils/env';

export const AuthProvider = ({ children }: { children?: React.ReactNode }) => {
  const navigate = useNavigate();

  if (!authClientId || !authDomain) throw Error('Invalid auth config');

  const providerConfig: Auth0ProviderOptions = {
    domain: authDomain,
    clientId: authClientId,
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
    onRedirectCallback: (appState) => {
      if (appState?.target) navigate(appState.target);
    },
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: authAudience,
    },
  };

  return <Auth0Provider {...providerConfig}>{children}</Auth0Provider>;
};
