/**
 * Expose environment variables through this file
 */
export const apiUrl = import.meta.env.APP_GRAPHQL_API; // The URL of the GraphQL API
export const mapboxToken = import.meta.env.APP_MAPBOX_TOKEN;
export const sentryDsn = import.meta.env.APP_SENTRY_DSN;
export const authDomain = import.meta.env.APP_AUTH0_DOMAIN;
export const authClientId = import.meta.env.APP_AUTH0_CLIENT_ID;
export const authAudience = import.meta.env.APP_AUTH0_AUDIENCE;
export const isDevBuild = import.meta.env.MODE === 'development'; // Whether or not VITE is running in development mode or not
export const isProductionBranch = import.meta.env.ENV_TYPE === 'production';
export const muiXLicenseKey = import.meta.env.APP_MUIX_LICENSE_KEY;
export const buildVersion = import.meta.env.BUILD_VERSION;
export const buildFingerprint = import.meta.env.BUILD_FINGERPRINT;
export const restApiUrl = apiUrl.replace('/query', ''); // The URL of the REST API
export const baseApiUrl = apiUrl.replace('/graph/query', ''); // The base URL of the API
export const googleAnalyticsId = import.meta.env.APP_GA_ID; // THe GA id, not present for anything but production
