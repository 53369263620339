/*
 * Collection of business rules related to all the assets
 */

import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { faSwitchboardBase } from 'icons/customFontAwesome/faSwitchboardBase';
import { faEnergyMeterBase } from 'icons/customFontAwesome/faEnergyMeterBase';
import { faBatteryBase } from 'icons/customFontAwesome/faBatteryBase';
import { faChargerBase } from 'icons/customFontAwesome/faChargerBase';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  AssetType,
  ReservationConnectionFragment,
  ChargerType,
  ProjectAllocationFragment,
} from 'api/generated/graphql';
import { faSwitchboard } from 'icons/customFontAwesome/faSwitchboard';
import { BatteryType } from 'types/assetTypes';
import { faEnergyMeter } from 'icons/customFontAwesome/faEnergyMeter';
import { faBatteryHub } from 'icons/batteries/faBatteryHub';
import { faBatterySystem } from 'icons/batteries/faBatterySystem';
import { faBatteryPack } from 'icons/batteries/faBatteryPack';
import { faFastCharger } from 'icons/chargers/faFastCharger';
import { faFastChargerWithBattery } from 'icons/chargers/faFastChargerWithBattery';
import { faNormalCharger } from 'icons/chargers/faNormalCharger';
import { faSingleCharger } from 'icons/chargers/faSingleCharger';
import { faGenericAsset } from 'icons/faGenericAsset';

export type AssetStatusStyle = {
  markerColor?: string;
  markerContrastColor?: string;
  badgeIcon?: IconDefinition;
  badgeIconColor?: string;
  zIndex?: number;
};

export type AssetRoutes = {
  details: string;
  list: string;
};

// TODO: Decide if this is needed
export const assetTypeStrings = [
  'Battery_System',
  'Battery_Hub',
  'Battery_Pack',
  'Switchboard',
  'EnergyMeter',
  'Charger_Fast',
  'Charger_FastWithBattery',
  'Charger_Normal',
  'Charger_Single',
] as const;
export type AssetTypeString = (typeof assetTypeStrings)[number];

/*
 * Returns icon for a specific asset
 */
export const getAssetBaseIcon = (assetType?: AssetType) => {
  if (assetType === AssetType.Battery) return faBatteryBase;
  if (assetType === AssetType.Charger) return faChargerBase;
  if (assetType === AssetType.Switchboard) return faSwitchboardBase;
  if (assetType === AssetType.EnergyMeter) return faEnergyMeterBase;
  if (assetType === AssetType.Generic) return faGenericAsset;

  return faQuestionCircle;
};

export type AssetTypeNames = {
  singular: string;
  plural: string;
};

export type AssetSubType = BatteryType | ChargerType | undefined;

export type ModelDataRow = {
  title: string;
  value: number | string | null | undefined;
  metric: string;
};

export type ReservationWithOverlapType = {
  startTime: string;
  endTime: string;
  overlappingReservations: ReservationConnectionFragment[];
};

/*
 * Type is extended with projects and reservations so we can find conflict situations easier
 */
export type AssignmentWithOverlapType = {
  startTime: string;
  endTime: string;
  overlappingAssignments: {
    projects: ProjectAllocationFragment[];
    reservations: ReservationConnectionFragment[];
  };
};

// Used to obtain different info for a certain asset type (and subtype)
export type AssetTypeConfig = {
  id: string; // Used for translations, etc. (e.g. 'BATTTERY_HUB')
  icon: IconDefinition;
} & (
  | {
      type: AssetType.Battery;
      subtype: BatteryType;
    }
  | {
      type: AssetType.Charger;
      subtype: ChargerType;
    }
  | {
      type: AssetType.Switchboard;
      subtype?: undefined;
    }
  | {
      type: AssetType.EnergyMeter;
      subtype?: undefined;
    }
  | {
      type: AssetType.Generic;
      subtype?: undefined;
    }
);

export const ALL_ASSET_SUBTYPES: AssetTypeConfig[] = [
  { type: AssetType.Battery, subtype: BatteryType.Hub, icon: faBatteryHub },
  { type: AssetType.Battery, subtype: BatteryType.Pack, icon: faBatteryPack },
  { type: AssetType.Battery, subtype: BatteryType.System, icon: faBatterySystem },
  { type: AssetType.Charger, subtype: ChargerType.Fast, icon: faFastCharger },
  {
    type: AssetType.Charger,
    subtype: ChargerType.FastWithBattery,
    icon: faFastChargerWithBattery,
  },
  { type: AssetType.Charger, subtype: ChargerType.Normal, icon: faNormalCharger },
  { type: AssetType.Charger, subtype: ChargerType.Single, icon: faSingleCharger },
  { type: AssetType.Switchboard, icon: faSwitchboard },
  { type: AssetType.EnergyMeter, icon: faEnergyMeter },

  { type: AssetType.Generic, icon: faGenericAsset },
].map(
  (a) =>
    ({
      ...a,
      id: a.subtype ? `${a.type.toUpperCase()}_${a.subtype?.toUpperCase()}` : a.type.toUpperCase(),
    }) as AssetTypeConfig // TODO: Avoid this casting. It destroys type safety
);
