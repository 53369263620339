import { AppBar, Toolbar, Link, Box, Stack } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { UserMenu } from './UserMenu';
import { EvinyLogoIcon } from 'icons/EvinyLogoIcon';
import { RootRoutes } from 'enums/rootRoutes';
import { useScreenSize } from 'utils/hooks/useScreenSize';
import { Breadcrumbs } from '../Breadcrumbs';
import { DebugInfo } from 'utils/DebugInfo';
import { isProductionBranch } from 'utils/env';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { AppSearchResponsive } from 'components/inputs/appSearch/AppSearch';

type NavbarProps = {
  sidebarWidth: number | null;
  navbarHeight: number;
};

export const Navbar = ({ sidebarWidth, navbarHeight }: NavbarProps) => {
  const { smScreenOrUp } = useScreenSize();
  const { isDeveloper, isInternal } = useCurrentUser();

  return (
    <AppBar
      position='relative'
      data-testid='navbar'
      sx={{
        background: (theme) => theme.sidebar?.active.background,
        color: '#fff',
        px: 0,
        height: navbarHeight,
      }}
      elevation={0}
    >
      <Toolbar sx={{ width: `calc(100vw - ${sidebarWidth}px)`, pl: 0 }}>
        {!smScreenOrUp && (
          <Box
            sx={{
              background: (theme) => theme.sidebar?.header.background,
              pt: 2,
              pb: 1,
              minWidth: 60,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Link component={NavLink} to={RootRoutes.HOME}>
              <EvinyLogoIcon />
            </Link>
          </Box>
        )}

        <Stack
          sx={{ flexGrow: 1, justifyContent: { xs: 'space-between', sm: 'start' } }}
          spacing={3}
          direction='row'
          alignItems='center'
        >
          <Breadcrumbs />
          {isInternal && <AppSearchResponsive />}
        </Stack>
        {isDeveloper && !isProductionBranch && <DebugInfo />}
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
};
